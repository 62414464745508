button {
  width: 3rem;
  height: 1.5rem;
  border-radius: 0.125rem;
  border-style: none;
  margin: 0.5em;
  padding: 0px;
  font-size: 1rem;
  background-color: #e1e1e1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

button:focus,
button:hover {
  background-color: #cdcdcd;
}

button:active {
  background-color: #bdbdbd;
}

.dark button {
  border-radius: 5px;
  border-style: none;
  font-size: 1rem;
  background-color: #457b9d;
}

.dark button:focus,
.dark button:hover {
  background-color: #386480;
  color: #ffffff;
}
.dark button:active {
  background-color: #2b4e64;
}
