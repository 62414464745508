* {
  font: 38px Futura, "Century Gothic", sans-serif;
  transition: background-color 500ms, color 500ms;
  background-color: #ffffff;
  color: #000000;
}

@media screen and (max-width: 500px) and (any-pointer: coarse) {
  * {
    font: 1em;
  }
  .helper * {
    display: None;
  }
}

body {
  min-width: 100vw;
  min-height: 100vh;
  padding: 0px;
  margin: auto;
  display: flex;
  align-items: centre;
}

div {
  text-align: center;
  margin: auto;
}

.helper * {
  font-family: monospace;
  font-size: 0.5em;
  margin-top: 0.5rem;
}

.dark,
.dark * {
  color: #f1faee;
  background-color: #1d3557;
}

.Title {
  /* font-size: 7rem; */
  font-size: clamp(2rem, 40vw, 100vw);
  margin: 0.1vh;
}
