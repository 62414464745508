.dark .Key span {
  color: black;
  font-family: monospace;
  font-size: 1em;
  display: inline-block;
  background-color: lightgreen;
  min-width: 1em;
  height: 1.2em;
  text-align: center;
  border-radius: 0.2em;
  padding: 0.2em 0.2em;
  margin: 0em 0.1em;
}

.Key span {
  color: firebrick;
  font-family: monospace;
  font-size: 1em;
  display: inline-block;
  background-color: gainsboro;
  min-width: 1em;
  height: 1.2em;
  text-align: center;
  border-radius: 0.2em;
  padding: 0.2em 0.2em;
  margin: 0em 0.1em;
}
